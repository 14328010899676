import "../../css/style.css";
import React, { useState, useEffect, useRef } from "react";
import { API_POST_Coursepost, API_Get_DropDown, API_POST_ExCouStud, API_POST_ExClass, API_POST_ExCouLottery, API_POST_ExSClass } from "../../global/constants";
import { useHistory } from "react-router-dom";
import { checkAuthToken } from "../../global/TokenUtil";
import Pagination from "../PaginationManage/Pagination";

function CourseProcess() {
    var history = useHistory();

    useEffect(() => {
        getData();
    }, []);
    

    function getData() {
        API_POST_Coursepost({
            CouName: DropDownCouName,
            CouPID: DropDownCouPID,
            CouTypeCID1: DropDownCouTypeCID1,
            CouTypeID: DropDownCouTypeID
        })
            .then((response) => response.data)
            .then((data) => {
                if (!checkAuthToken(data)) {
                    window.location = "/"; 
                }
                if (data.code == "0000") {
                    console.log("DATA",data);
                    setDataList(data.result);
                    setTotalPage(Math.ceil(data.result.length / 20));
                    setPageData(data.result.filter((item, index) => index >= (0 + 20 * selectPage) && index < (20 + 20 * selectPage)))
                    setSelectPage(0); //換搜尋時回到第一頁的頁碼
                }
                else {
                    console.log(data.message);
                    setDataList([]);
                }
            })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });

        API_Get_DropDown()
            .then((response) => response.data)
            .then((data) => {

                if (!checkAuthToken(data)) {
                    window.location = "/";
                }
                if (data.code == "0000") {
                    console.log(data);
                    setcoursePeriodArrayList(data.result.coursePeriodArray);
                    setcourseTypeCArrayList(data.result.courseTypeCArray);
                    setcourseTypeArrayList(data.result.courseTypeArray);
                    
                }
                else {
                    alert(data.message);
                }
            })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });

    }
    const FileDownload = require("js-file-download");
    const [modalStr, setModalStr] = useState("查無資料,無法刪除");
    const [tmpIndex, setTmpIndex] = useState();
    const [dataList, setDataList] = useState([]);
    const [coursePeriodArrayList, setcoursePeriodArrayList] = useState([{}]);//期別清單
    const [courseTypeCArrayList, setcourseTypeCArrayList] = useState([{}]);//類別清單
    const [courseTypeArrayList, setcourseTypeArrayList] = useState([{}]);//班別清單 
    const [DropDownCouName, setDropDownCouName] = useState("");//課程名稱
    const [DropDownCouPID, setDropDownCouPID] = useState(""); //期別下拉
    const [DropDownCouTypeID, setDropDownCouTypeID] = useState("");//類別下拉
    const [DropDownCouTypeCID1, setDropDownCouTypeCID1] = useState("");//班別下拉
    const [totalPage, setTotalPage] = useState(0);//總頁數
    const [pageData, setPageData] = useState([]);//每頁資訊
    const [selectPage, setSelectPage] = useState(0); //選擇的頁數
    const pageView = useRef(null); //得到指定元素的操作
    useEffect(() => { 
        //changePageData();
        backToTop();  
    }, [selectPage])

    //function changePageData(){
    //    setPageData(dataList.filter((item, index) => index >= (0 + 20 * selectPage) && index < (20 + 20 * selectPage)));
    //} 
    function backToTop() {
        if (pageView.current) {
            pageView.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start', // 将容器顶部对齐视口
            });
        }
    }
    function Edit(type, index) {
        console.log(index);
        if (type == 2 && dataList[index].id == null) {
            //按下的是新增
            //呼叫API新增
            
        } else {
            setDataList(function (prev) {
                return prev.map((item, i) => {
                    if (i == index) {
                        item.isEdit = type == 1 ? true : false;
                    }
                    return item;
                });
            });
        }
    }
    function delData() {
        //呼叫API

        //成功
        setDataList(function (prev) {
            return prev.filter((x, i) => i != tmpIndex);
        });
        //失敗
        //setModalStr('xxxxx');
        //openModalError();
    }

    function openModalCheck(tmpIndex) {
        setTmpIndex(tmpIndex);
        var id = "modal-check";
        var target = document.querySelector("#" + id);
        var layerClass = "layer-1";
        target.classList.add("active");
        target.classList.add(layerClass);
    }
    function openModalError() {
        var id = "modal-error";
        var target = document.querySelector("#" + id);
        var layerClass = "layer-1";
        target.classList.add("active");
        target.classList.add(layerClass);
    }


    //課程抽選名單
    function DownloadRaw(index, rptCode) {
        API_POST_ExCouLottery({
            CouID: dataList[index].CouID,
            rptCode: rptCode
        }).then((response) => {
            FileDownload(response.data, dataList[index].CouName + "電腦抽選名單.xlsx");
        })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 404) {
                    alert("下載失敗");
                    return;
                }
            });
    }

    //下載
    function Download(index, rptCode) {
        var FileName = "";
        /*   API_POST_ExClass*/
        switch (rptCode) {
            case "CouCheck":
                FileName = "簽到表";
                _tmp = dataList[index].CouID;
                break;
            case "CouStud":
                FileName = "學員列表";
                _tmp = dataList[index].CouID;
                break;
            case "CouPhone":
                FileName = "通訊錄";
                _tmp = dataList[index].CouID;
                break;  
        }




        API_POST_ExCouStud({
            CouID: _tmp,
            rptCode: rptCode
        }).then((response) => {
                FileDownload(response.data, dataList[index].CouName + FileName + ".xlsx");
        })
        .catch((err) => {
            console.log(err);
            if (err.response.status === 404) {
                alert("下載失敗");
                return;
            }
        });
        
        
    }

    //下載簽到表
    function ClassDownload(index) {
        var FileName = "";
    
        API_POST_ExClass({
            CouID: dataList[index].CouID,
        }).then((response) => {
            FileDownload(response.data, dataList[index].CouName + FileName + ".xlsx");
        })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 404) {
                    alert("下載失敗");
                    return;
                }
            });
    }


    function ClassSDownload(_index) {
        API_POST_ExSClass({
            CouPID: _index,
            _coupname: dataList[0].CouPName
        }).then((response) => {
            FileDownload(response.data, dataList[0].CouPName + "當期簽到表.zip");
        })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 404) {
                    alert("下載失敗");
                    return;
                }
            });


    }
    return (
        <>
            {console.log("總頁數", totalPage) }
            <div className="main" >
                <div className="page-content">
                    <div className="content-body" ref={pageView}>
                        <div className="title-area">
                            <div className="containerB full">
                                <h3 className="fz-A mr-1" id="h3">課程過程管理</h3>
                            </div>
                        </div>
                        <div className="container full">
                            <div className="form-container">
                                <div className="form-item twenty">
                                    <div className="top-table">期別</div>
                                    <div className="top-value">
                                        <select value={DropDownCouPID} onChange={(e) => setDropDownCouPID(e.target.value)}>
                                            <option value="">請選擇</option>
                                            {coursePeriodArrayList.map((y) => (
                                                <option value={y.id}>{y.name} </option>
                                            ))}  
                                        </select>
                                    </div>
                                </div>
                                <div className="form-item twenty">
                                    <div className="top-table">類別</div>
                                    <div className="top-value">
                                        <select value={DropDownCouTypeCID1} onChange={(e) => setDropDownCouTypeCID1(e.target.value)}>
                                            <option value="">請選擇</option>
                                            {courseTypeCArrayList.map((y) =>(
                                                <option value={y.id}>{y.name} </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="form-item twenty">
                                    <div className="top-table">班別</div>
                                    <div className="top-value">
                                        <select value={DropDownCouTypeID} onChange={(e) => setDropDownCouTypeID(e.target.value)}>
                                            <option value="">請選擇</option>
                                            {courseTypeArrayList.map((y) => (
                                                < option value={y.id}>{y.name} </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="form-item twenty">
                                    <div className="top-table">課程名稱</div>
                                    <div className="top-value">
                                        <input type="text" value={DropDownCouName} onChange={(e) => setDropDownCouName(e.target.value)} />
                                    </div>
                                </div>
                                <a className="btn btn-border btn-middle" onClick={getData}>
                                    <span>查詢</span>
                                </a>
                            </div>
                        </div>

                        <div className="container full mt-1">
                            <div>
                                <h5 className="mr-2">下載功能圖示說明</h5>
                               
                            </div>
                            <div className="form-item">
                                <i className="icon icon-18"></i>
                                <div className="mr-1">下載報名學員列表</div>

                                <i className="icon icon-19"></i>
                                <div className="mr-1">下載報名學員通訊錄</div>

                                <i className="icon icon-20"></i>
                                <div className="mr-1">下載電腦抽選名單</div>

                                <i className="icon icon-21"></i>
                                <div>下載學員簽到表</div>

                                
                            </div>
                           


                            <div className="modal-body">
                                <ul className="list-table">
                                    <li className="lt-row lt-th">
                                        <div className="flex-0">ID</div>
                                        <div className="flex-1">班別序號</div>
                                        <div className="flex-2">課程名稱</div>
                                        <div className="flex-2">課程期別</div>
                                        <div className="flex-1">類別</div>
                                        <div className="flex-1">班別</div>
                                        <div className="flex-1">教師</div>
                                        <div className="flex-2">開課日期</div>
                                        <div className="flex-1">名額</div>
                                        <div className="flex-1">報名</div>
                                        <div className="flex-1">錄取</div>
                                        <div className="flex-1">繳費</div>
                                        <div className="flex-4">&ensp;下載</div>
                                    </li>
                                    {console.log("渲染", pageData) }
                                    {pageData.map((x, i) => (
                                        <li className="lt-row">
                                            <div className="flex-0">{(selectPage+1)*20-20+i+1}</div>
                                            <div className="flex-1">
                                                {x.CouNo}
                                            </div>
                                            <div className="flex-2">
                                                {x.CouName}
                                            </div>
                                            <div className="flex-2">
                                                {x.CouPName}
                                            </div>
                                            <div className="flex-1">
                                                {x.Kind}
                                            </div>
                                            <div className="flex-1">
                                                {x.ClassType}
                                            </div>
                                            <div className="flex-1">
                                                {x.CouTeacherName}
                                            </div>
                                            <div className="flex-2">
                                                {x.CouStartDate}
                                            </div>
                                            <div className="flex-1">
                                                {x.CouAmount}
                                            </div>
                                            <div className="flex-1">
                                                {x.D0Cnt}
                                            </div>
                                            <div className="flex-1">
                                                {x.D2Cnt}
                                            </div>
                                            <div className="flex-1">
                                                {x.P2Cnt}
                                            </div>
                                            <div className="flex-4 fn">
                                                <div className="fn-bar">
                                                    <div className="btn btn-icon" title="下載報名學員列表" onClick={(e) => Download(((selectPage + 1) * 20 - 20 + i),"CouStud")} >
                                                        <i className="icon icon-18"></i>
                                                    </div>

                                                    <div className="btn btn-icon" title="下載報名學員通訊錄" onClick={(e) => Download(((selectPage + 1) * 20 - 20 + i),"CouPhone")} >
                                                        <i className="icon icon-19"></i>
                                                    </div>

                                                    <div className="btn btn-icon" title="下載電腦抽選名單" onClick={(e) => DownloadRaw(((selectPage + 1) * 20 - 20 + i),"CouLottery")}>
                                                        <i className="icon icon-20"></i>
                                                    </div>

                                                    <div className="btn btn-icon" title="下載學員簽到表" onClick={(e) => ClassDownload(((selectPage + 1) * 20 - 20 + i),"CouCheck")}>
                                                        <i className="icon icon-21"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                           
                            {DropDownCouPID ?
                                <div
                                    className="mr-1"
                                    onClick={(e) => ClassSDownload(DropDownCouPID)}
                                    style={{ cursor: "pointer", float: "right" }}
                                >
                                    下載當期簽到表
                                </div> : <div>   </div>}
                        </div>    
                        <div className="container full">
                            
                            {pageData ?
                                //<>
                                   
                                //    <div className="pagination">
                                //        <span className="page-link cursor-pointer" onClick={() => {
                                //            setSelectPage(selectPage - 1)
                                //        }} style={{ display: selectPage ? 'block' : 'none' }}>
                                //            上一頁
                                //        </span>
                                //        {[...Array(totalPage)].map((item, index) => {
                                //            return (
                                //                <span

                                //                    key={index + 1}
                                //                    className={`page-link cursor-pointer ${index == selectPage && 'active'}`}
                                //                    onClick={(e) => { setSelectPage(index) }}
                                //                >
                                //                    {index + 1}
                                //                </span>
                                //            );
                                //        })}
                                //        <span className="page-link cursor-pointer" onClick={() => {
                                //            setSelectPage(selectPage + 1)
                                //        }} style={{ display: selectPage + 1 !== totalPage ? 'block' : 'none' }}>
                                //            下一頁
                                //        </span>
                                //    </div>
                                   
                                //</>
                                <Pagination setTotalPage={setTotalPage} selectPage={selectPage} setSelectPage={setSelectPage} pageData={pageData} totalPage={totalPage} setPageData={setPageData} dataList={dataList}></Pagination>
                                :
                                <></>
                            }
                            
                        </div>
                    </div>
                </div>
                <div id="modal-check" className="modal small" data-modal>
                    <div className="modal-container">
                        <div className="modal-body">
                            <div className="icon-holder">
                                <i className="icon icon-43"></i>
                            </div>
                            <div className="text-holder">確認刪除?</div>
                            <div className="btn-holder">
                                <a className="btn btn-border" data-close>
                                    <span>取消</span>
                                </a>
                                <a className="btn" data-close onClick={delData}>
                                    <span>確認</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="modal-error" className="modal small" data-modal>
                    <div className="modal-container">
                        <div className="modal-body">
                            <div className="icon-holder">
                                <i className="icon icon-43"></i>
                            </div>
                            <div className="text-holder">刪除失敗</div>
                            <div className="btn-holder">失敗原因:{modalStr}</div>
                        </div>
                    </div>
                </div>
                
               
                
            </div>
        </>
    );
}
export default CourseProcess;
