import "../../css/style.css";
import React, { useState, useEffect } from "react";
import { API_POST_AddCourseType, API_POST_SaleRptpost, API_Get_DropDown, API_POST_SaleRpt, API_POST_SaleRptRow } from "../../global/constants";
import { useHistory } from "react-router-dom";
import { checkAuthToken } from "../../global/TokenUtil";

function PfmReport() {
    var history = useHistory();
    const FileDownload = require("js-file-download");
    useEffect(() => {

        //getData();
        get_DropDown_data();
        
    }, []);

    function getData() {
        API_POST_SaleRptpost({
            CouPID: DropDownCouPID,
            CouTypeCID: DropDownCouTypeCID1,
            CouTypeID: DropDownCouTypeID
        })
            .then((response) => response.data)
            .then((data) => {

                if (!checkAuthToken(data)) {
                    window.location = "/";
                }
                if (data.code == "0000") {
                    console.log(data);
                    setDataList(data.result);
                }
                else {
                    alert(data.message);
                    setDataList([]);
                }
            })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });

        API_Get_DropDown()
            .then((response) => response.data)
            .then((data) => {

                if (!checkAuthToken(data)) {
                    window.location = "/";
                }
                if (data.code == "0000") {
                    console.log(data);
                    setcoursePeriodArrayList(data.result.coursePeriodArray);
                    setcourseTypeCArrayList(data.result.courseTypeCArray);
                    setcourseTypeArrayList(data.result.courseTypeArray);
                }
                else {
                    alert(data.message);
                }
            })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });

    }
    function get_DropDown_data() {
        API_Get_DropDown()
            .then((response) => response.data)
            .then((data) => {

                if (!checkAuthToken(data)) {
                    window.location = "/";
                }
                if (data.code == "0000") {
                    console.log(data);
                    setcoursePeriodArrayList(data.result.coursePeriodArray);
                    setcourseTypeCArrayList(data.result.courseTypeCArray);
                    setcourseTypeArrayList(data.result.courseTypeArray);
                }
                else {
                    alert(data.message);
                }
            })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 401 || err.response.data.code == "1001") {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }
    const [coursePeriodArrayList, setcoursePeriodArrayList] = useState([{}]);//期別清單
    const [courseTypeCArrayList, setcourseTypeCArrayList] = useState([{}]);//類別清單
    const [courseTypeArrayList, setcourseTypeArrayList] = useState([{}]);//班別清單
    const [DropDownCouPID, setDropDownCouPID] = useState(""); //期別下拉
    const [DropDownCouTypeID, setDropDownCouTypeID] = useState("");//類別下拉
    const [DropDownCouTypeCID1, setDropDownCouTypeCID1] = useState("");//班別下拉

    const [modalStr, setModalStr] = useState("查無資料,無法刪除");
    const [tmpIndex, setTmpIndex] = useState();
    const [CouSID, setCouSID] = useState("");//期別
    const [dataList, setDataList] = useState([]);
    function Edit(type, index) {
        console.log(index);
        if (type == 2 && dataList[index].id == null) {
            //按下的是新增
            //呼叫API新增
            API_POST_AddCourseType({
                CouTypeName: dataList[index].name,
            })
                .then((response) => response.data)
                .then((data) => {
                    console.log(data);
                    if (data.code == "0000") {
                        //成功
                        //getDashboard();
                        //localStorage.setItem('token', data.token.token);
                        //localStorage.setItem('user', data.result.loginData.userNo);
                        //localStorage.setItem('userName', data.result.loginData.userName);
                        //localStorage.setItem('userID', data.result.loginData.userID);
                        //history.push('?path=changepassword')
                    } else if (data.code == "2013") {
                        //特定的失敗原因
                    } else {
                        setMsgStr(data.message);
                    }

                    //console.log(localStorage.getItem('menulist'));
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            setDataList(function (prev) {
                return prev.map((item, i) => {
                    if (i == index) {
                        item.isEdit = type == 1 ? true : false;
                    }
                    return item;
                });
            });
        }
    }
    function Download(rptCode) {
        var FileName = "";
        /*   API_POST_ExClass*/
        switch (rptCode) {
            case "SaleRpt":
                FileName = "業務總表";
                rptCode = DropDownCouPID;
                break;
            case "SaleReg":
                FileName = "業務報名統計表";
                break;
            case "SaleRefund":
                FileName = "業務退費統計表";
                break;
        }

        API_POST_SaleRpt({
            rptCode: rptCode
        }).then((response) => {
            FileDownload(response.data,  FileName + ".xlsx");
        })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 404) {
                    alert("下載失敗");
                    return;
                }
            }
            );
    }


    function DownloadT(name,ID) {
        var FileName = "";
        FileName = name;
     
        API_POST_SaleRptRow({
            CouID: ID,
        }).then((response) => {
            FileDownload(response.data, FileName + ".xlsx");
        })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 404) {
                    alert("下載失敗");
                    return;
                }
            }
            );
    }

    return (
        <>
            <div className="main">
                <div className="page-content">
                    <div className="content-body">
                        <div className="title-area">
                            <div className="containerB full">
                                <h3 className="fz-A mr-1">業務統計報表</h3>
                            </div>
                        </div>
                        
                        <div className="container full">
                            <div className="form-container">
                                <div className="form-item twenty">
                                    <div className="top-table">期別</div>
                                    <div className="top-value">
                                        <select value={DropDownCouPID} onChange={(e) => setDropDownCouPID(e.target.value)}>
                                            <option value="">請選擇</option>
                                            {coursePeriodArrayList.map((y) => (
                                                <option value={y.id}>{y.name} </option>
                                            ))}  
                                        </select>
                                    </div>
                                </div>
                                <div className="form-item twenty">
                                    <div className="top-table">類別</div>
                                    <div className="top-value">
                                        <select value={DropDownCouTypeCID1} onChange={(e) => setDropDownCouTypeCID1(e.target.value)}>
                                            <option value="">請選擇</option>
                                            {courseTypeCArrayList.map((y) => (
                                                <option value={y.id}>{y.name} </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="form-item twenty">
                                    <div className="top-table">班別</div>
                                    <div className="top-value">
                                        <select value={DropDownCouTypeID} onChange={(e) => setDropDownCouTypeID(e.target.value)}>
                                            <option value="">請選擇</option>
                                            {courseTypeArrayList.map((y) => (
                                                < option value={y.id}>{y.name} </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <a className="btn btn-border btn-middle" onClick={getData}>
                                    <span>查詢</span>
                                </a>
                               </div>
                        </div>

                        <div className="container full mt-1">
                            <div className="modal-body">
                                <ul className="list-table">
                                    <li className="lt-row lt-th">
                                        <div className="flex-0">ID</div>
                                        <div className="flex-1">班別序號</div>
                                        <div className="flex-2">課程名稱</div>
                                        <div className="flex-2">課程期別</div>
                                        <div className="flex-1">類別</div>
                                        <div className="flex-1">班別</div>
                                        <div className="flex-2">結束日期</div>
                                        <div className="flex-2">報名/錄取/名額</div>   
                                      {/*  <div className="flex-1">上傳點名表</div>   */}
                                        <div className="flex-1"> 查看統計</div>
                                    </li>
                                    {dataList.length  ? dataList.map((x, i) => (
                                        <li className="lt-row">
                                            <div className="flex-0">{i + 1}</div>
                                            <div className="flex-1">{x.CouNo}</div>
                                            <div className="flex-2">{x.CouName}</div>
                                            <div className="flex-2">{x.CouPName}</div>
                                            <div className="flex-1">{x.Kind}</div>
                                            <div className="flex-1">{x.ClassType}</div>
                                            <div className="flex-2">{x.CouCloseDate}</div>
                                            <div className="flex-2">{x.D0Cnt}/{x.CouAmountT}/{x.CouAmount }</div>
                                            {/*<div className="flex-1 fn">*/}
                                            {/*    <div className="fn-bar">*/}
                                            {/*        <div*/}
                                            {/*            className="btn btn-icon"*/}
                                            {/*            onClick={(e) => Edit(1, i)}*/}
                                            {/*        >*/}
                                            {/*            <i className="icon icon-27"></i>*/}
                                            {/*        </div> */}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                            <div className="flex-1 fn">
                                                <div className="fn-bar">
                                                    <div
                                                        className="btn btn-icon"
                                                        onClick={(e) => DownloadT(x.CouName, x.CouID)}
                                                    >
                                                        <i className="icon icon-20"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    )) :  <li className="lt-row" style={{ display: 'flex', justifyContent: 'center' }}>
                                        <div style={{ textAlign: 'center' }}>尚未選擇期別</div>
                                    </li>}
                                    {dataList.length ? <li className="lt-row">
                                        <div className="form-item">
                                            <i className="icon icon-18"></i>
                                            <div className="mr-1"
                                                onClick={(e) => Download("SaleReg")} style={{ cursor: "pointer" }}>下載報名狀況統計表</div>

                                            <i className="icon icon-28"></i>
                                            <div className="mr-1"
                                                onClick={(e) => Download("SaleRefund")} style={{ cursor: "pointer" }}>下載退費統計表</div>

                                            <i className="icon icon-29"></i>
                                            <div onClick={(e) => Download("SaleRpt")} style={{ cursor: "pointer" }}>下載全部數據</div>
                                        </div>
                                    </li>
                                        :""}
                                </ul>
                            </div>
                            
                        </div>


                    </div>
                </div>
            </div>
        </>
    );
}
export default PfmReport;
